<template>
  <div class="verify-email-container">
    <v-card class="verify-email-card">
      <img
        class="verify-bkash-logo"
        src="@/assets/image/bKash-logo.svg"
        alt="img"
      />
      <span class="text-title">Welcome Back to </span>
      <span class="text-details">bKash Business<br />Dashboard</span>
      <span class="success-text"
        >Your email address has been <br />updated successfully!</span
      >
      <span class="footer-text"
        >Want to check your account info?<a
          @click="$router.push({ name: 'sign-in' })"
          style="color: #ffcb15; font-weight: bold; margin-left: 10px"
          >SIGN IN</a
        ></span
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: "EmailVerificationSuccess",
  beforeMount() {
    let isMailVerify = this.$route.query["verification-key"];
    if (isMailVerify) {
      this.logoutAndVerifyEmail(isMailVerify);
    } else {
      this.$router.push({ name: "sign-in" });
    }
  },
  methods: {
    async logoutAndVerifyEmail(hash) {
      this.$feedback.showLoading();

      try {
        if (this.$store.getters.isAuthenticated) {
          await this.$cppClient.post("logout", {
            idToken: this.$store.getters.getIdToken,
          });
          this.$store.commit('clearPageState');
          this.$store.commit("logoutUser");
        }

        await this.$cppClient.post("/profile/update/email/verify", {
          verificationKey: hash,
        });
        this.$feedback.hideLoading();
      } catch (err) {
        this.$store.commit('clearPageState');
        this.$store.commit("logoutUser");
        this.$feedback.hideLoading();
        this.$logger.loggable(err).log();
        this.$feedback.showAutoFailed(err);
        this.$router.push({ name: "sign-in" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$mobileW: 550px;
$mobileSMW: 350px;

.verify-email-container {
  display: flex;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  .verify-email-card {
    display: flex;
    width: 519px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobileW) {
      width: 80%;
    }
    @media (max-width: $mobileSMW) {
      width: 100%;
    }

    .verify-bkash-logo {
      width: 180px;
      height: 83px;
      margin-top: 30px;
      margin-bottom: 20px;
      @media (max-width: $mobileW) {
        width: 120px;
        height: 60px;
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }

    .text-title {
      font-size: 45px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #707070;
      margin-bottom: 10px;
      @media (max-width: $mobileW) {
        font-size: 30px;
      }
    }
    .text-details {
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #95989a;
      @media (max-width: $mobileW) {
        font-size: 15px;
      }
    }
    .success-text {
      font-size: 34px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #95989a;
      margin-top: 89px;
      @media (max-width: $mobileW) {
        font-size: 15px;
        margin-top: 49px;
      }
    }
    .footer-text {
      margin-top: 112px;
      margin-bottom: 119px;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #95989a;
      @media (max-width: $mobileW) {
        font-size: 15px;
        margin-top: 62px;
        margin-bottom: 69px;
      }
    }
  }
}
</style>
